(function($) {

    var defaults = {};

    // Constructor
    function FormImage(element, options) {
        this.element = element;
        this.img = $($(element).data('image'));
        this.settings = $.extend({}, options, defaults);
        this.init();
    }

    // Plugin initialization
    FormImage.prototype.init = function() {
        var self = this;

        // hide the form input
        $(self.element).hide();

        // add the hidden input to record the action to be taken
        self.hidden_input = $('<input type="hidden" name="' + $(self.element).attr('name') + '_action" value="keep">');
        self.hidden_input.insertAfter($(self.element));

        // add the links to change and remote the image
        self.change_link = $('<a href="#">choose image</a>');
        self.remove_link = $('<a href="#">remove image</a>');
        if ($(this.img).data('state') === 'empty')
            self.remove_link.hide();
        self.links_container = $('<div class="form-image-links">').prepend(self.change_link).append(self.remove_link);
        self.links_container.insertAfter($(self.element));

        // add click events to the links
        this.addClickToChangeLink();
        this.addClickToRemoveLink();

        // add change event to file input
        this.addChangeToInput();

    };

    // Do stuff when the change link is clicked
    FormImage.prototype.addClickToChangeLink = function() {
        var self = this;
        $(self.change_link).on('click', function(e) {
            e.preventDefault();
            $(self.element).trigger('click');
        });
    };

    // Do stuff when the remove link is clicked
    FormImage.prototype.addClickToRemoveLink = function () {
        var self = this;

        $(self.remove_link).on('click', function(e) {
            e.preventDefault();
            $(self.img).attr('src', '').hide();
            $(self.hidden_input).val('remove');
            $(self.remove_link).hide();
        });
    };

    // Handle the change event on the file input
    FormImage.prototype.addChangeToInput = function() {
        var self = this;
        $(self.element).on('change', function() {
            var fileReader = new FileReader();
            fileReader.onload = (function(img) {
                return function(e) {
                    img.attr('src', e.target.result);
                };
            })(self.img);
            fileReader.readAsDataURL(this.files[0]);
            $(self.img).show();
            $(self.hidden_input).val('change');
            $(self.remove_link).show();
        });
    };

    // jQuery plugin wrapper
    $.fn['formImage'] = function(options) {
        return this.each(function() {
            if ( ! $.data(this, 'formImage_plugin')) {
                $.data(this, 'formImage_plugin', new FormImage(this, options));
            }
        });
    };

})(jQuery);