(function ($) {

    // Constructor
    function AgeAxe(element, options) {
        this.element = element;
        this.settings = $.extend({}, $.fn.ageAxe.defaults, options);
        this.init();
    }

    // Plugin initialization
    AgeAxe.prototype.init = function () {
        var self = this;
        $(self.element).on('submit', function (e) {
            e.preventDefault();
            if ($(self.element).hasClass('is-submitting'))
                return false;
            $(self.element).addClass('is-submitting');
            self.submit();
        });
    };

    // Submit the form
    AgeAxe.prototype.submit = function () {
        var self = this;
        if (self.start() === false) {
            $(self.element).removeClass('is-submitting');
            return false;
        }
        $.ajax({
            url: $(self.element).attr('action'),
            type: 'post',
            data: self.formData,
            contentType: false,
            processData: false,
            xhr: function () {
                return self.progress();
            }
        }).done(function (response) {
            self.success(response);
        }).fail(function (response) {
            self.error(response);
        }).always(function () {
            self.finish();
        });
    };

    AgeAxe.prototype.start = function () {
        this.formData = new FormData(this.element);
        if (this.settings.start(this) === false) return false;
        $(this.element).find('[type="submit"]').attr('disabled', true);
        $(this.element).find('.' + this.settings.error_message_class).remove();
        $(this.element)
            .find('.' + this.settings.group_error_class)
            .removeClass(this.settings.group_error_class);
    };

    AgeAxe.prototype.finish = function () {
        $(this.element).removeClass('is-submitting');
        $(this.element).find('[type="submit"]').attr('disabled', false);
        this.settings.always(this);
    };

    AgeAxe.prototype.error = function (response) {
        if (response.status === 401) this.settings.unauthorized();
        this.settings.error(response, this);
    };

    AgeAxe.prototype.success = function (response) {
        this.settings.success(response);
    };

    AgeAxe.prototype.progress = function () {
        var self = this;
        var xhr = new window.XMLHttpRequest();
        xhr.upload.addEventListener("progress", function (evt) {
            if (evt.lengthComputable) {
                self.settings.updateProgress(evt.loaded / evt.total * 100);
            }
        }, false);
        xhr.addEventListener("progress", function (evt) {
            if (evt.lengthComputable) {
                self.settings.updateProgress(evt.loaded / evt.total * 100);
            }
        }, false);
        return xhr;
    };

    AgeAxe.prototype.insertFormErrors = function (errors) {
        var self = this;
        $.each(errors, function (key, val) {
            var parent = $(self.element);
            var input = parent.find('[name="' + key + '"]');
            var container = parent.find('.error-container.' + key);

            input.closest(self.settings.group_selector).addClass(self.settings.group_error_class);

            if (container.length < 1) {
                container = input;
            }
            container.after('<p class="' + self.settings.error_message_class + '">' + val[0] + '</p>');
        });
    };

    // jQuery plugin wrapper
    $.fn.ageAxe = function (options) {
        return this.each(function () {
            if (!$.data(this, 'ageAxe_plugin')) {
                $.data(this, 'ageAxe_plugin', new AgeAxe(this, options));
            }
        });
    };

    $.fn.ageAxe.defaults = {
        start: function () {
        },
        success: function () {
        },
        error: function (response, instance) {
            instance.insertFormErrors(response.responseJSON);
        },
        always: function () {
        },
        unauthorized: function () {
            window.location.reload();
        },
        updateProgress: function (percent) {
        },
        error_message_class: 'help-block',
        group_error_class: 'has-error',
        group_selector: '.form-group'
    };

})(jQuery);
