var formStatus;

(function($) {

    formStatus =  {
        success: function() {
            addFormStatus('success');
        },
        error: function() {
            addFormStatus('error');
        }
    };

    /*
     |--------------------------------------------------------------------------
     | Helper functions
     |--------------------------------------------------------------------------
     */

    function addFormStatus(status_type) {
        $('.form-status').remove();
        var status = $('<div class="form-status">').addClass(status_type);
        $('body').append(status);
        setTimeout(function() { $(status).addClass('on'); }, 100);
        setTimeout(function() { hideStatus(status); }, 5000);
    }
    function hideStatus(status) {
        $(status).removeClass('on');
        setTimeout(function() { $(status).remove(); }, 200);
    }

})(jQuery);